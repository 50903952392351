import LazyLoad from '@/services/LazyLoad'
import PlatformStore from "mg-js-core/lib/stores/PlatformStore";
import AuthLogic from "mg-js-core/lib/logics/AuthLogic";

export default [
    {
        path: '/login',
        name: 'login',
        // component: LoginView,
        component: LazyLoad.localView('auth/Login'),
    },
    {
        path: '/login-admin',
        beforeEnter: (to, from, next) => {
            if(to.query.token){
                AuthLogic
                    .loginWithToken({token: to.query.token,})
                    .then(() => next({name: 'member-dashboard'}))
                    .catch(() => AuthLogic.logout().then(() => next({name: 'login'})));
            } else {
                AuthLogic.logout().then(() => next({name: 'login'}))
            }
        },
        name: 'login-admin',
    },
    {
        path: '/register',
        name: 'register',
        // component: LoginView,
        component: LazyLoad.localView('auth/Register'),
    },
    {
        path: '/register-ressource',
        name: 'register-ressource',
        // component: LoginView,
        component: LazyLoad.localView('auth/RegisterRessource'),
    },
    {
        path: '/free-course-register/:productSlug/:selectedItem',
        name: 'free-course-register',
        // component: LoginView,
        component: LazyLoad.localView('auth/FreeCourseRegister'),
    },
    {
        path: '/information',
        name: 'information',
        component: LazyLoad.localView('auth/Information'),
    },
    {
        path: '/need-email-verification',
        name: 'need-email-verification',
        component: LazyLoad.localView('auth/NeedEmailVerification'),
    },
    {
        path: '/email-validation/:userUuid/:validationToken',
        name: 'email-validation',
        component: LazyLoad.localView('auth/EmailValidation'),
    },
    {
        path: '/step-2-resource',
        name: 'information-ressource',
        component: LazyLoad.localView('auth/InformationResource'),
    },
    {
        path: '/quiz/:questionUuid?',
        beforeEnter: (to, from, next) => {
            if(PlatformStore.notMyPianoPop){
                next()
            }else{
                next(from)
            }
        },
        name: 'quiz',
        component: LazyLoad.localView('quiz/QuizShow'),
    },
    {
        path: '/quiz/:answerUuid/recommendations',
        beforeEnter: (to, from, next) => {
            if(PlatformStore.notMyPianoPop){
                next()
            }else{
                next(from)
            }
        },
        name: 'quiz-recommendation',
        component: LazyLoad.localView('quiz/QuizShowRecommendation'),
    },
    {
        path: '/password/force',
        name: 'force-reset-password',
        component: LazyLoad.localView('auth/ForceResetPassword'),
    },
    {
        path: '/password/reset',
        name: 'reset-password',
        component: LazyLoad.localView('auth/ResetPassword'),
    },
    {
        path: '/password/update/:token',
        name: 'update-password',
        component: LazyLoad.localView('auth/UpdatePassword'),
    },
    {
        // not found handler
        name: 'AccessDenied',
        path: '/403',
        // component: DenyView,
        component: LazyLoad.localView('Deny'),
    },
    {
        // not found handler
        name: 'NotFound',
        path: '/404',
        // component: NotFoundView,
        component: LazyLoad.localView('NotFound'),
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        // component: LoginView,
        component: LazyLoad.localView('Maintenance'),
    },
    {
        // not found handler
        name: 'redirect',
        path: '*',
        redirect: { name: 'member-dashboard' },
    },
    {
        name: 'offline-cart',
        path: '/invite-cart/:productUuid/:cartFunnelStep?',
        props: true,

        component: LazyLoad.localView('member/offline-cart/OfflineCartFunnel')
    },
    
]
